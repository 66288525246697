<script setup>
const props = defineProps(["filter", "filtersStore"])
const value =Array.isArray(props.filtersStore.selected_filters[props.filter.slug]?.value)
  ? ref(props.filtersStore.selected_filters[props.filter.slug]?.value)
  : ref([]);

const setValue = () => {
  props.filtersStore.updateValue(props.filter, value.value);
}
</script>

<template>
  <div class="border-b border-b-slate-200 pb-3">
    <div class="px-3 mt-3">
      {{ filter.name }}
    </div>
    <div class="px-3 mt-1">
      <div class="grid grid-cols-2">
      <template v-for="(item, idx) in filter.data_source" :key="idx">
      <template v-if="(filter.filter_value_more > 0 && filter.filter_value_more >= idx)">
        <w-checkbox :value="item" @change="setValue()" v-model="value" >
          <template #label>
            {{ item.label_value || item.value }}
            {{ filter.filter_value_more == idx ? '+' : '' }}  
          </template>
        </w-checkbox>
      </template>
    </template>
    </div>
  </div>
  </div>
</template>
